<template>
  <div id="app">
    <h1>座位列表</h1>
    <SeatList />
  </div>
</template>

<script>
import SeatList from './components/SeatList.vue'

export default {
  components: {
    SeatList
  }
}
</script>

<style>
#app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #2c3e50;
  margin-bottom: 1em;
}
</style>