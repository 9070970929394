<template>
  <div class="main-container">
    <!-- 按钮容器 -->
    <div class="button-group">
      <button 
        class="toggle-btn"
        @click="toggleShowAll"
        :style="toggleButtonStyle"
      >
        {{ showAll ? '收起座位表' : '展开全部座位（共240个）' }}
      </button>
      
      <!-- 新增登录按钮 -->
      <button
        class="login-btn"
        @click="navigateToLogin"
      >
        <span class="icon">🔑</span>
        登录其他账号
      </button>
    </div>

    <!-- 座位容器 -->
    <div class="seat-container">
      <!-- 动态渲染座位 -->
      <div 
        v-for="seat in displayedSeats"
        :key="seat"
        class="seat-block"
        :class="{ 'original-seat': isOriginalSeat(seat) }"
        @click="openSeatLink(seat)"
      >
        {{ seat }}
        <div v-if="isOriginalSeat(seat)" class="original-tag">常用</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const allSeats = Array.from({ length: 240 }, (_, index) => 
      (index + 1).toString().padStart(3, '0')
    );
    
    return {
      baseUrl: 'https://office.chaoxing.com/front/third/apps/seat/code?id=12019&seatNum=',
      originalSeats: ['157','158', '159', '160', '161', '162', '163','036'], // 初始常用座位
      allSeats: allSeats,
      showAll: false,
      loginUrl: 'https://passport2.chaoxing.com/mlogin?loginType=1&newversion=true&fid='
    };
  },
  computed: {
    displayedSeats() {
      return this.showAll ? this.allSeats : this.originalSeats;
    },
    toggleButtonStyle() {
      return {
        backgroundColor: this.showAll ? '#ff6b6b' : '#4CAF50',
      };
    }
  },
  methods: {
    openSeatLink(seatNum) {
      window.open(`${this.baseUrl}${seatNum}`, '_blank');
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    isOriginalSeat(seat) {
      return this.originalSeats.includes(seat);
    },
    // 新增登录方法
    navigateToLogin() {
      window.open(this.loginUrl, '_blank');
    }
  }
};
</script>

<style scoped>
/* 新增按钮容器样式 */
.button-group {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

/* 登录按钮样式 */
.login-btn {
  padding: 12px 24px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
}

.login-btn:hover {
  background-color: #42A5F5;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 14px;
}

/* 调整原按钮样式 */
.toggle-btn {
  padding: 12px 24px;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 其他原有样式保持不变 */
.main-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.seat-container {
  display: flex;
  gap: 15px;
  padding: 20px;
  flex-wrap: wrap;
  background-color: #f8f9fa;
  border-radius: 12px;
  min-height: 50px;
}

.seat-block {
  position: relative;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  min-width: 80px;
  text-align: center;
  font-family: monospace;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 22px;
  font-size: large;
}

.seat-block:hover {
  background-color: #dee2e6;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.original-seat {
  background-color: #b2f2bb !important;
  border: 2px solid #2f9e44;
  height: 20px;
  font-size: large;
}

.original-seat:hover {
  background-color: #8ce99a !important;
}

.original-tag {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #2f9e44;
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-family: sans-serif;
}
</style>












